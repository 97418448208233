import { FunctionComponent, useCallback, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../store/context/auth-context";

interface LogoutProps {}

const Logout: FunctionComponent<LogoutProps> = () => {
	const authCtx = useContext(AuthContext);
	const navigate = useNavigate();

	const logoutFunction = useCallback(async () => {
		authCtx.logout();
		if (!authCtx.isLoggedIn) navigate("/entdecken", { replace: true });
	}, [authCtx, navigate]);

	useEffect(() => {
		logoutFunction();
	}, [logoutFunction]);
	return <></>;
};

export default Logout;
