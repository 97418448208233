import React, { FunctionComponent, Suspense, useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Spinner from "./components/ui/Spinner/Spinner";
import BasePageLayout from "./layout/BasePageLayout/BasePageLayout";
import ScrollToTop from "./layout/ScrollToTop";
import Explore from "./pages/Explore/Explore";
import Logout from "./pages/User/Logout";
import AuthContext from "./store/context/auth-context";

interface RouterProps {}

const BookDetail = React.lazy(() => import("./pages/BookDetail/BookDetail"));
const AddNewBook = React.lazy(() => import("./pages/AddNewBook/AddNewBook"));

const UserList = React.lazy(() => import("./pages/Admin/UserList/UserList"));
const UserDetail = React.lazy(
	() => import("./pages/Admin/UserDetail/UserDetail")
);
const BookEdit = React.lazy(() => import("./pages/BookEdit/BookEdit"));

const Borrows = React.lazy(() => import("./pages/Moderator/Borrows/Borrows"));
const AddNewBorrow = React.lazy(
	() => import("./pages/AddNewBorrow/AddNewBorrow")
);
const BorrowEdit = React.lazy(() => import("./pages/BorrowEdit/BorrowEdit"));

// const Requests = React.lazy(() => import("./pages/Moderator/Requests/Requests"));
// const RequestDetail = React.lazy(() => import("./pages/Moderator/Requests/RequestDetail/RequestDetail"));

const Dashboard = React.lazy(() => import("./pages/User/Dashboard/Dashboard"));
const Login = React.lazy(() => import("./pages/User/Login/Login"));
const Register = React.lazy(() => import("./pages/User/Register/Register"));
const PasswordReset = React.lazy(
	() => import("./pages/User/PasswordReset/PasswordReset")
);

const TermsOfUse = React.lazy(
	() => import("./pages/Law/TermsOfUse/TermsOfUse")
);
const NotFound = React.lazy(() => import("./pages/NotFound"));

const Router: FunctionComponent<RouterProps> = () => {
	const authCtx = useContext(AuthContext);
	const isLoggedIn: boolean = authCtx.isLoggedIn;

	const isModerator: boolean =
		(authCtx.roles.includes("moderator") ||
			authCtx.roles.includes("admin")) &&
		isLoggedIn;
	const isAdmin: boolean = authCtx.roles.includes("admin") && isLoggedIn;

	return (
		<main>
			<ScrollToTop />
			<BasePageLayout>
				<Suspense fallback={<Spinner center />}>
					<Routes>
						<Route
							path={"/"}
							element={<Navigate replace to={"/entdecken"} />}
						/>
						<Route path={"entdecken"} element={<Explore />} />
						<Route
							path={"entdecken/buch-infos/:id"}
							element={<BookDetail />}
						/>
						<Route path={"anmelden"} element={<Login />} />
						<Route
							path="passwort-zuruecksetzen"
							element={
								isLoggedIn ? (
									<PasswordReset />
								) : (
									<Navigate replace to={"/entdecken"} />
								)
							}
						/>
						<Route
							path={"dashboard"}
							element={
								isLoggedIn ? (
									<Dashboard />
								) : (
									<Navigate replace to="/entdecken" />
								)
							}
						/>
						<Route path="/abmelden" element={<Logout />} />
						{/* MODERATOR ROUTES */}
						<Route
							path={"moderator/buch-hinzufuegen"}
							element={
								isModerator ? (
									<AddNewBook />
								) : (
									<Navigate replace to={"/entdecken"} />
								)
							}
						/>
						<Route
							path="entdecken/buch-infos/:id/bearbeiten"
							element={
								isModerator ? (
									<BookEdit />
								) : (
									<Navigate replace to={"/entdecken"} />
								)
							}
						/>
						<Route
							path="moderator/ausleihen"
							element={
								isModerator ? (
									<Borrows />
								) : (
									<Navigate replace to={"/entdecken"} />
								)
							}
						/>
						<Route
							path="moderator/ausleihen/:id"
							element={
								isModerator ? (
									<BorrowEdit />
								) : (
									<Navigate replace to={"/entdecken"} />
								)
							}
						/>
						<Route
							path="moderator/ausleihen/neue-erstellen/"
							element={
								isModerator ? (
									<AddNewBorrow />
								) : (
									<Navigate replace to={"/entdecken"} />
								)
							}
						/>
						<Route
							path="moderator/ausleihen/neue-erstellen/:id"
							element={
								isModerator ? (
									<AddNewBorrow />
								) : (
									<Navigate replace to={"/entdecken"} />
								)
							}
						/>
						{/* MODERATOR ROUTES */}
						{/* ADMIN ROUTES */}
						<Route
							path={"admin/nutzer-registrieren"}
							element={
								isAdmin ? (
									<Register />
								) : (
									<Navigate replace to={"/entdecken"} />
								)
							}
						/>
						<Route
							path="admin/nutzer-liste"
							element={
								isAdmin ? (
									<UserList />
								) : (
									<Navigate replace to={"/entdecken"} />
								)
							}
						/>
						<Route
							path="admin/nutzer-liste/:id"
							element={
								isAdmin ? (
									<UserDetail />
								) : (
									<Navigate replace to={"/entdecken"} />
								)
							}
						/>
						{/* ADMIN ROUTES */}
						<Route
							path="nutzungsbedingungen"
							element={<TermsOfUse />}
						/>
						<Route path={"*"} element={<NotFound />} />
					</Routes>
				</Suspense>
			</BasePageLayout>
		</main>
	);
};

export default Router;
