import { FunctionComponent, useContext } from "react";
import { NavLink } from "react-router-dom";
import AuthContext from "../../../store/context/auth-context";
import styles from "./Navigation.module.css";

interface NavigationProps {
	mobileMenuVisible: boolean;
	onMobileMenuToggle: () => void;
	onClose: () => void;
}

const Navigation: FunctionComponent<NavigationProps> = (props) => {
	const authCtx = useContext(AuthContext);

	const navLinkClasses = props.mobileMenuVisible
		? `${styles["nav-links"]} ${styles.active}`
		: styles["nav-links"];
	const toggleButtonClasses = props.mobileMenuVisible
		? `${styles["toggle-button"]} ${styles.active}`
		: styles["toggle-button"];

	const userButtons = authCtx.isLoggedIn ? (
		<>
			<li>
				<NavLink
					to={"/dashboard"}
					className={(navData) =>
						navData.isActive ? styles.active : ""
					}
					onClick={props.onClose}
				>
					Dashboard
				</NavLink>
			</li>
			<li>
				<NavLink to={"/abmelden"} onClick={props.onClose}>
					Abmelden
				</NavLink>
			</li>
		</>
	) : (
		<>
			{/* <li>
				<NavLink
					to={"/anmelden"}
					className={(navData) =>
						navData.isActive ? styles.active : ""
					}
					onClick={props.onClose}
				>
					Anmelden
				</NavLink>
			</li>
			<li>
				<NavLink
					to={"/registrieren"}
					className={(navData) =>
						navData.isActive ? styles.active : ""
					}
					onClick={props.onClose}
				>
					Registrieren
				</NavLink>
			</li> */}
		</>
	);

	return (
		<>
			<nav
				className={
					props.mobileMenuVisible
						? `${styles.navigation} ${styles.active}`
						: styles.navigation
				}
			>
				<div className={navLinkClasses}>
					<ul className={styles.nav__group}>
						<li>
							<NavLink
								to={"/entdecken"}
								className={(navData) =>
									navData.isActive ? styles.active : ""
								}
								onClick={props.onClose}
							>
								Entdecken
							</NavLink>
						</li>
						{authCtx.isLoggedIn &&
							authCtx.roles.includes("moderator") && (
								<li>
									<NavLink
										onClick={props.onClose}
										to={"/moderator/buch-hinzufuegen"}
										className={(navData) =>
											navData.isActive
												? styles.active
												: ""
										}
									>
										Buch hinzufügen
									</NavLink>
								</li>
							)}
						{/* <li>
							<NavLink
								to={"/standort"}
								className={(navData) =>
									navData.isActive ? styles.active : ""
								}
								onClick={props.onClose}
							>
								Standort
							</NavLink>
						</li> */}
					</ul>
					<ul className={styles.nav__group}>{userButtons}</ul>
				</div>
				<div
					className={toggleButtonClasses}
					onClick={props.onMobileMenuToggle}
				>
					<span className={styles.bar} />
					<span className={styles.bar} />
					<span className={styles.bar} />
				</div>
			</nav>
		</>
	);
};

export default Navigation;
