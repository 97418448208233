import { FunctionComponent, useEffect } from "react";
import { useLocation } from "react-router-dom";

interface ScrollToTopProps {
    
}
 
const ScrollToTop: FunctionComponent<ScrollToTopProps> = () => {

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0,0);
    }, [location.pathname])
    return <></>;
}
 
export default ScrollToTop;