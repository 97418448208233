import React, {
	FunctionComponent,
	useCallback,
	useEffect,
	useState,
} from "react";
import { UserRole } from "../../pages/User/UserInfos.model";
import { calculateRemainingTime } from "./calculateRemainingTime";
import { retrieveStoredData } from "./retrieveStoreData";

interface AuthContextProviderProps {
	children: React.ReactNode;
}

interface Payload {
	token: string;
	userid: string;
	roles: UserRole[];
	createdAt: string;
	expiresIn: string; // get the iso date string
}

let logoutTimer: any;

const AuthContext = React.createContext({
	token: "",
	userid: "",
	roles: [] as UserRole[],
	createdAt: "",
	isLoggedIn: false,
	login: (data: Payload) => {},
	logout: () => {},
});

export const AuthContextProvider: FunctionComponent<
	AuthContextProviderProps
> = (props) => {
	const recievedData = retrieveStoredData();
	let defaultValues = {
		token: "",
		userid: "",
		roles: [] as UserRole[],
		createdAt: "",
		expiresIn: 0,
	};
	if (recievedData) defaultValues = recievedData;
	const [token, setToken] = useState(defaultValues.token);
	const [userId, setUserId] = useState(defaultValues.userid);
	const [roles, setRoles] = useState<UserRole[]>(defaultValues.roles);
	const [createdAt, setCreatedAt] = useState(defaultValues.createdAt);
	const [expiresIn, setExpiresIn] = useState(recievedData.expiresIn);

	const isLoggedIn = !!token && !!roles;

	const logoutHandler = useCallback(() => {
		setToken("");
		setUserId("");
		setRoles([]);
		setCreatedAt("");
		setExpiresIn(0);
		localStorage.removeItem("data");

		if (logoutTimer) clearTimeout(logoutTimer);
	}, []);

	const loginHandler = (data: Payload) => {
		setToken(data.token);
		setUserId(data.userid);
		setRoles(data.roles);
		setCreatedAt(data.createdAt);
		const remainingTime = calculateRemainingTime(data.expiresIn);
		setExpiresIn(remainingTime);
		localStorage.setItem("data", JSON.stringify(data));

		logoutTimer = setTimeout(logoutHandler, remainingTime);
	};

	useEffect(() => {
		logoutTimer = setTimeout(logoutHandler, expiresIn);
	}, [expiresIn, logoutHandler]);

	const contextValue = {
		token,
		userid: userId,
		roles,
		createdAt,
		// expiresIn,
		isLoggedIn,
		login: loginHandler,
		logout: logoutHandler,
	};

	return (
		<AuthContext.Provider value={contextValue}>
			{props.children}
		</AuthContext.Provider>
	);
};

export default AuthContext;
