import { FunctionComponent, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import useHttp from "../../hooks/use-http";
import { booksActions, BookState } from "../../store/books-slice";
import BookItem, { BookInfos } from "../../components/BookItem/BookItem";
import BookList from "./BookList/BookList";
import ExploreHeading from "./Heading/ExploreHeading";

interface ExploreProps {}

interface Response {
	books: BookInfos[];
	amount: number;
}

const Explore: FunctionComponent<ExploreProps> = () => {
	const [query, setQuery] = useSearchParams();
	const library: string = query.get("library") || "";
	const search: string = query.get("search") || "";

	const bookSelector: BookState = useSelector((state: any) => state.books);
	const dispatchBooks = useDispatch();

	const { isLoading, error, sendRequest } = useHttp();

	const loadMoreHandler = () => {
		listBooks(bookSelector.books.length, transformModeBookData);
	};

	const transformModeBookData = useCallback(
		(data: Response) => {
			dispatchBooks(booksActions.addBooks(data.books));
		},
		[dispatchBooks]
	);

	const transformBookData = useCallback(
		(data: Response) => {
			dispatchBooks(booksActions.setBooks(data.books));
			dispatchBooks(booksActions.setCount(data.amount));
		},
		[dispatchBooks]
	);

	const listBooks = useCallback(
		(min: number, handleFunction: Function) => {
			let url = `books/list?min=${min}&max=10`;
			if (library) {
				url += `&library=${library}`;
				query.set("library", library);
			} else query.delete("library");
			if (search) {
				url += `&search=${search}`;
				query.set("search", search);
			} else query.delete("search");
			setQuery(query);
			sendRequest(
				{
					url,
				},
				handleFunction
			);
		},
		[sendRequest, library, search, query, setQuery]
	);

	useEffect(() => {
		listBooks(0, transformBookData);
	}, [listBooks, transformBookData]);

	const bookItems = bookSelector.books.map((item, index) => {
		return <BookItem key={item + "_" + index} data={item} />;
	});

	return (
		<>
			<ExploreHeading />
			<BookList
				books={bookItems}
				bookAmount={bookSelector.count}
				isLoading={isLoading}
				error={error?.message || ""}
				loadMore={loadMoreHandler}
			/>
		</>
	);
};

export default Explore;
