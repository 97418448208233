import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import styles from './../Button/Button.module.css'

interface AnchorProps {
    to: string;
    children?: React.ReactNode;
    className?: string;
    alt?: boolean;
    arrow?: boolean;
}
 
const Anchor: FunctionComponent<AnchorProps> = (props) => {
    const classes = {
        arrow: props.alt ? `${styles.arrow__alt} ${styles.arrow}` : styles.arrow,
        button: props.alt ? `${styles.button__alt} ${styles.button}` : styles.button
    }
    const buttonClasses = props.arrow ? `${classes.button} ${styles.button__arrow}`: classes.button;
	return (
		<Link to={props.to} className={buttonClasses}>
			{props.children}
			{props.arrow && (
				<svg width="20" height="20" viewBox="0 0 20 20" className={classes.arrow}>
					<path
						className={classes.arrow}
						d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"
					></path>
				</svg>
			)}
		</Link>
	);
}
 
export default Anchor;