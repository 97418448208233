import { FunctionComponent, useState } from "react";
import styles from "./Header.module.css";
import logo from "./../../assets/img/logo/logo.svg";
import Navigation from "./Navigation/Navigation";
import { Link } from "react-router-dom";

interface HeaderProps {}

const Header: FunctionComponent<HeaderProps> = () => {
	const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

	const menuToggleHandler = () => {
		setMobileMenuVisible((prevState) => !prevState);
	};

	const menuCloseHandler = () => {
		setMobileMenuVisible(false);
	}

	return (
		<>
			{mobileMenuVisible && <div className={styles.backdrop} onClick={menuCloseHandler} />}
			<header className={styles.header}>
				<Link to={"/entdecken"} onClick={menuCloseHandler}>
					<img
						src={logo}
						alt="JANUN - Library Logo"
						className={styles.logo}
					/>
				</Link>
				<Navigation
					mobileMenuVisible={mobileMenuVisible}
					onMobileMenuToggle={menuToggleHandler}
					onClose={menuCloseHandler}
				/>
			</header>
		</>
	);
};

export default Header;
