import { createSlice } from "@reduxjs/toolkit";
import { BookInfos } from "../components/BookItem/BookItem";
const initialState = {
    books: [] as BookInfos[],
	count: 0,
	library: '' as Library,
};

export type Library = '' | 'hannover' | 'lueneburg' | 'oldenburg';
export interface BookState {
	books: BookInfos[];
	count: number;
	library: Library;
}

const booksSlice = createSlice({
	name: "books",
	initialState: initialState,
	reducers: {
		addBooks (state, action: { payload: BookInfos[] }) {
			state.books.push(...action.payload);
		},
		setBooks (state, action: {payload: BookInfos[]}) {
			state.books = [...action.payload];
		},
		setCount (state, action: {payload: number}) {
			state.count = action.payload;
		},
		setLibrary(state, action: {payload: Library}) {
			state.library = action.payload;
		},
	},
});

export const booksActions = booksSlice.actions;

export default booksSlice.reducer;
