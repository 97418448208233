import React, {
	ButtonHTMLAttributes,
	FunctionComponent,
	MouseEventHandler,
} from "react";
import { To, useNavigate } from "react-router-dom";
import styles from "./Button.module.css";

interface ButtonProps extends ButtonHTMLAttributes<any> {
	children?: React.ReactNode;
	alt?: boolean;
	arrow?: boolean;
	className?: string;
	number?: number;
	to?: To;
	replace?: boolean;
	onClick?: MouseEventHandler;
}

const Button: FunctionComponent<ButtonProps> = (props) => {
	const navigate = useNavigate();
	const classes = {
		arrow: props.alt
			? `${styles.arrow__alt} ${styles.arrow}`
			: styles.arrow,
		button: props.alt
			? `${styles.button__alt} ${styles.button}`
			: styles.button,
	};
	let buttonClasses = props.arrow
		? `${classes.button} ${styles.button__arrow}`
		: classes.button;

	const { className, onClick, to, replace, alt, arrow, ...newProps } = props;

	const onClickFunction = to
		? () => {
				navigate(to, { replace });
		  }
		: onClick;

	if (props.className) buttonClasses = props.className + " " + buttonClasses;

	const number = props.number
		? props.number > 1000
			? "1000+"
			: props.number?.toString()
		: "";

	return (
		<button
			className={buttonClasses}
			onClick={onClickFunction}
			{...newProps}
		>
			{props.children}
			{props.arrow && (
				<svg
					width="20"
					height="20"
					viewBox="0 0 20 20"
					className={classes.arrow}
				>
					<path
						className={classes.arrow}
						d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"
					></path>
				</svg>
			)}
			{!!props.number && props.number > 0 && (
				<div className={styles.number}>
					<span className={styles.number__span}>{number}</span>
				</div>
			)}
		</button>
	);
};

export default Button;
