import {
	ChangeEvent,
	FormEvent,
	FunctionComponent,
	useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import Button from "../../../components/ui/Button/Button";
import { Library } from "../../../store/books-slice";
import styles from "./ExploreHeading.module.css";
import LibrarySelect from "./LibrarySelect";

interface ExploreHeadingProps {}

const ExploreHeading: FunctionComponent<ExploreHeadingProps> = () => {

	const [query, setQuery] = useSearchParams();
	const library = query.get("library") || ("" as Library);
	const search = query.get("search");

	const [searchValue, setSearchValue] = useState(search?.toString() || "")

	const submitHandler = (e: FormEvent) => {
		e.preventDefault();
		if (library) query.set("library", library);
		else query.delete("library");
		if (!!searchValue && searchValue.trim().length > 0)
			query.set("search", searchValue);
		else query.delete("search");
		setQuery(query);
	};

	return (
		<>
			<div className={styles.heading}>
				<LibrarySelect /*onSelect={selectHandler}*/ />
				<h1>Entdecken</h1>
				<form className={styles.search} onSubmit={submitHandler}>
					<input
						type="search"
						onChange={(e: ChangeEvent<HTMLInputElement>) => {setSearchValue(e.currentTarget.value)}}
						className={styles["search-bar"]}
						placeholder="IBSN, Titel oder Author"
						value={searchValue}
					/>
					<Button arrow type="submit">
						Suchen
					</Button>
				</form>
			</div>
		</>
	);
};

export default ExploreHeading;
