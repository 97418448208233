import { ChangeEvent, FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { booksActions, Library } from "../../../store/books-slice";
import styles from "./ExploreHeading.module.css";

interface LibrarySelectProps {}

const LibrarySelect: FunctionComponent<LibrarySelectProps> = (props) => {
	const [query] = useSearchParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const lib: Library = (query.get("library") as Library) || "";
	const search: string = query.get("search") || "";

	const selectChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
		const library: Library = e.currentTarget.value as Library;
		dispatch(booksActions.setLibrary(library));
		if (search)
			return navigate(`/entdecken?library=${library}&search=${search}`);
		navigate(`/entdecken?library=${library}`);
	};
	return (
		<select
			name="library"
			id="library"
			className={styles.select}
			value={lib}
			onChange={selectChangeHandler}
		>
			<option value="">Alle anzeigen</option>
			<option value="hannover">Hannover</option>
			<option value="lueneburg">Lüneburg</option>
			<option value="oldenburg">Oldenburg</option>
		</select>
	);
};

export default LibrarySelect;
