import { FunctionComponent, useContext } from "react";

import styles from "./Footer.module.css";

import janunlogo from "./../../assets/img/logo/janun_logo_bw.svg";
import logo from "./../../assets/img/logo/logo_bw.svg";
import telegram from "./../../assets/img/icons/socialmedia/telegram.png";
import instagram from "./../../assets/img/icons/socialmedia/instagram.png";
import facebook from "./../../assets/img/icons/socialmedia/facebook.png";
import youtube from "./../../assets/img/icons/socialmedia/youtube.png";
import twitter from "./../../assets/img/icons/socialmedia/twitter.png";
import { Link } from "react-router-dom";
import AuthContext from "../../store/context/auth-context";
import Anchor from "../../components/ui/Anchor/Anchor";

interface FooterProps {}

const Footer: FunctionComponent<FooterProps> = () => {
	const authCtx = useContext(AuthContext);

	return (
		<footer className={styles.footer}>
			<div className={styles.advertising}>
				<div className={styles.logos}>
					<a href="https://www.janun.de/" target={"blank"}>
						<img
							src={janunlogo}
							alt="JANUN Logo"
							className={styles.logo}
						/>
					</a>
					<Link to="/">
						<img
							src={logo}
							alt="JANUN Logo"
							className={styles["library-logo"]}
						/>
					</Link>
				</div>
				<div className={styles["social-media"]}>
					<a href="https://t.me/janunnds" target="blank">
						<img
							src={telegram}
							alt=""
							className={styles["social-media-link"]}
						/>
					</a>
					<a
						href="https://www.instagram.com/janun_nds/"
						target="blank"
					>
						<img
							src={instagram}
							alt=""
							className={styles["social-media-link"]}
						/>
					</a>
					<a
						href="https://www.facebook.com/jugendumweltnetzwerk/"
						target="blank"
					>
						<img
							src={facebook}
							alt=""
							className={styles["social-media-link"]}
						/>
					</a>
					<a
						href="https://www.youtube.com/user/JANUNvideo"
						target="blank"
					>
						<img
							src={youtube}
							alt=""
							className={styles["social-media-link"]}
						/>
					</a>
					<a href="https://twitter.com/janun_nds" target="blank">
						<img
							src={twitter}
							alt=""
							className={styles["social-media-link"]}
						/>
					</a>
				</div>
			</div>
			<div className={styles.legal}>
				{!authCtx.isLoggedIn && (
					<Anchor to="/anmelden">Website-Login</Anchor>
				)}
				<a
					href="https://www.janun.de/de/impressum/"
					target={"_blank"}
					rel="noreferrer"
				>
					Impressum
				</a>
				<a
					href="https://www.janun.de/de/datenschutz/"
					target={"_blank"}
					rel="noreferrer"
				>
					Datenschutz
				</a>
				<Link to="/nutzungsbedingungen">Nutzungsbedingungen</Link>
			</div>
			<div className={styles.links}></div>
		</footer>
	);
};

export default Footer;
