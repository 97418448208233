import { FunctionComponent, useEffect, useState } from "react";
import ClickableContainer from "../ui/Container/ClickableContainer/ClickableContainer";
import styles from "./BookItem.module.css";
import noCover from "./../../assets/placeholders/nocoverfound.png";
import blocked from "./../../assets/img/icons/blocked.svg";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useHttp from "../../hooks/use-http";

interface BookItemProps {
	data: BookInfos;
}

export interface BookInfos {
	_id?: string;
	title: string;
	subtitle: string;
	authors: string[];
	publisher: string;
	publishedDate: Date;
	isbn10: string;
	isbn13: string;
	description: string;
	language: string;
	library: string;
	available: boolean;
	imageUrl: string;
	borrow?: string;
}
export interface BookInfosWithId {
	_id: string;
	title: string;
	subtitle: string;
	authors: string[];
	publisher: string;
	publishedDate: Date;
	isbn10: string;
	isbn13: string;
	description: string;
	language: string;
	library: string;
	available: boolean;
	imageUrl: string;
	borrow?: string;
}
const BookItem: FunctionComponent<BookItemProps> = (props) => {
	const window = useWindowDimensions();
	const { isLoading, sendRequest } = useHttp();

	const [cover, setCover] = useState<any>(noCover);

	const book = { ...props.data };
	const isbn =
		book.isbn13 !== "/"
			? [
					book.isbn13?.substring(0, 3),
					book.isbn13?.substring(3, 4),
					book.isbn13?.substring(4, 8),
					book.isbn13?.substring(8, 12),
					book.isbn13?.substring(12, 13),
			  ]
			: ["/"];

	// get cover via backend
	useEffect(() => {
		if (book.imageUrl !== "noimage" || book.imageUrl.length > 7 )
			sendRequest(
				{
					url: "books/get-cover",
					method: "POST",
					body: { url: book.imageUrl.toString() },
				},
				(data: any) => {
					setCover(`data:image/jpeg;base64,${data.image}`);
				}
			);
		else {
			setCover(noCover);
		}
	}, [sendRequest, book.imageUrl]);

	let title =
		book.subtitle !== "/" ? `${book.title} - ${book.subtitle}` : book.title;

	let sliceSize = 70;
	if (window.width < 760) sliceSize = 45;
	if (window.width < 340) sliceSize = 30;
	if (window.width <= 280) sliceSize = 20;

	if (title.length > sliceSize) title = `${title.slice(0, sliceSize)}...`;
	const date = new Date(book.publishedDate);

	const coverClasses = book.available
		? styles.cover
		: `${styles.notavailable} ${styles.cover}`;
	return (
		<li className={styles["list-item"]}>
			<ClickableContainer
				to={"buch-infos/" + book._id}
				className={styles.container}
			>
				<div className={styles["cover-group"]}>
					{!book.available && (
						<img
							className={styles.blocked}
							src={blocked}
							alt={"Durchgestrichen"}
						/>
					)}
					{!isLoading && (
						<img
							className={coverClasses}
							src={cover}
							alt="Cover von dem Buch"
						/>
					)}

					{isLoading && (
						<div className={styles["cover-placeholder"]} />
					)}
				</div>

				<div className={styles.infos}>
					<div className={styles["book-infos"]}>
						<span className={styles.isbn}>
							ISBN {isbn.join("-")}
						</span>
						<h3 className={styles.title}>
							{title} {!book.available && "(ausgeliehen)"}
						</h3>
						<span className={styles.author}>{book.authors[0]}</span>
					</div>
					{window.width >= 760 && (
						<div className={styles["publisher-infos"]}>
							<span className={styles.publisher}>
								{book.publisher}
							</span>
							<span className={styles["published-year"]}>
								{date.getFullYear().toString()}
							</span>
						</div>
					)}
				</div>
			</ClickableContainer>
		</li>
	);
};

export default BookItem;
