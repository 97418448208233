import { FunctionComponent, MouseEventHandler } from "react";

import Spinner from "../../../components/ui/Spinner/Spinner";

import styles from "./BookList.module.css";
import Button from "../../../components/ui/Button/Button";
import { useSearchParams } from "react-router-dom";
import ReloadButton from "../../../components/ui/ReloadButton/ReloadButton";

interface BookListProps {
	books: JSX.Element[];
	bookAmount: number;
	loadMore: MouseEventHandler;
	isLoading: boolean;
	error: string;
}

const BookList: FunctionComponent<BookListProps> = (props) => {
	const [query, setQuery] = useSearchParams();

	const search = query.get("search");

	const showAllBooksHandler = async () => {
		query.delete("search");
		setQuery(query);
	};

	const content = props.books;
	const buttonClasses = props.error
		? `${styles.button} ${styles.error}`
		: `${styles.button}`;
	return (
		<>
			{!!search && content.length >= 5 && (
				<Button
					className={buttonClasses + " " + styles.top}
					alt
					onClick={showAllBooksHandler}
				>
					Alle Bücher anzeigen
				</Button>
			)}
			{props.isLoading && !!!props.error && <Spinner />}
			{!props.isLoading && !!props.error && (
				<>
					<p className={styles.p + "error"}>
						Es gab einen Fehler beim Laden der Bücher.
					</p>
					<ReloadButton alt />
				</>
			)}
			{!!!props.error && content.length > 0 && (
				<ul className={styles["book-list"]}>
					{!props.error && content.length > 0 && content}
				</ul>
			)}
			{!!!props.error && content.length <= 0 && (
				<p>Es konnte kein Buch gefunden werden.</p>
			)}
			{content.length > 0 &&
				content.length < props.bookAmount && !!!props.error &&
				!!!search && (
					<Button alt onClick={props.loadMore}>
						Mehr laden
					</Button>
				)}
			{!!search && (
				<Button
					className={styles.button}
					alt
					onClick={showAllBooksHandler}
				>
					Alle Bücher anzeigen
				</Button>
			)}
		</>
	);
};

export default BookList;
