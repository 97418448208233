import { combineReducers } from "@reduxjs/toolkit";
import booksSlice, { BookState } from "./books-slice";
import usersSlice, { UsersState } from "./users-slice";

export interface State {
    books: BookState;
    users: UsersState;
}

const rootReducers = combineReducers({books: booksSlice, users: usersSlice});

export default rootReducers;