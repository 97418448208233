import { createSlice } from "@reduxjs/toolkit";
import { UserInfos } from "../pages/User/UserInfos.model";
const initialState = {
    users: [] as UserInfos[],
	count: 0,
};
export interface UsersState {
	users: UserInfos[];
	count: number;
}

const usersSlice = createSlice({
	name: "users",
	initialState: initialState,
	reducers: {
		addUsers (state, action: { payload: UserInfos[] }) {
			state.users.push(...action.payload);
		},
		setUsers (state, action: {payload: UserInfos[]}) {
			state.users = [...action.payload];
		},
		setCount (state, action: {payload: number}) {
			state.count = action.payload;
		},
		
	},
});

export const usersActions = usersSlice.actions;

export default usersSlice.reducer;
