import { UserRole } from "../../pages/User/UserInfos.model";
import { calculateRemainingTime } from "./calculateRemainingTime";

interface StoredData {
    token: string;
    userid: string;
    roles: UserRole[];
    createdAt: string;
    expiresIn: string;
}

export const retrieveStoredData = () => {
    const storedData = localStorage.getItem("data");
    let initialData: StoredData = {
        token: "",
        expiresIn: "",
        roles: [],
        userid: "",
        createdAt: "",
    };
    if (storedData) initialData = JSON.parse(storedData);
    const remainingTime = calculateRemainingTime(initialData.expiresIn);
    if (remainingTime <= 3600) {
        localStorage.removeItem("data");
        return {
            token: "",
            expiresIn: 0,
            roles: [],
            userid: "",
            createdAt: "",
        };
    }
    return { ...initialData, expiresIn: remainingTime };
};