import { FunctionComponent } from "react";
import styles from './Spinner.module.css'

interface SpinnerProps {
    center?: boolean;
}
 
const Spinner: FunctionComponent<SpinnerProps> = (props) => {
    const classes = props.center ? `${styles.spinner} centered`: styles.spinner;
    return ( <div className={classes}/> );
}
 
export default Spinner;