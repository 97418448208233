import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./ui.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import { AuthContextProvider } from "./store/context/auth-context";

ReactDOM.render(
	<React.StrictMode>
		<AuthContextProvider>
			<BrowserRouter>
				<Provider store={store}>
					<App />
				</Provider>
			</BrowserRouter>
		</AuthContextProvider>
	</React.StrictMode>,
	document.getElementById("root")
);
