import { FunctionComponent } from "react";
import styles from "./BasePageLayout.module.css";

interface BasePageLayoutProps {
	children?: React.ReactNode;
}

const BasePageLayout: FunctionComponent<BasePageLayoutProps> = (props) => {
	return <div className={styles.layout}>{props.children}</div>;
};

export default BasePageLayout;
