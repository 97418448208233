import { useCallback, useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../store/context/auth-context";

interface RequestConfig {
	url: string;
	method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
	headers?: HeadersInit;
	body?: Object;
}

const useHttp = () => {
	const mounted = useRef(false);
	const { isLoggedIn, token } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<{ message: string, code: number }>();

	useEffect(() => {
		mounted.current = true;
		return () => {
			mounted.current = false;
		}
	}, [])

	const sendRequest = useCallback(async (requestConfig: RequestConfig, applyData: Function) => {
		setIsLoading(true);
		setError(undefined);

		// Basic Setup
		// if user is logged in automatically add the bearer token header
		let headers = {};
		if (isLoggedIn) headers = { Authorization: "Bearer " + token };
		// if body is given automatically add the content-type header
		if (requestConfig.body) headers = { ...headers, "content-type": "application/json" };
		// if no http is in the url use the api url
		let url = 'https://library.janun.de/api/' + requestConfig.url;
		if (requestConfig.url.includes('http://') || requestConfig.url.includes('https://')) url = requestConfig.url;

		try {
			const response = await fetch(url, {
				method: requestConfig.method ? requestConfig.method : 'GET',
				headers: requestConfig.headers ? { ...requestConfig.headers, ...headers } : headers,
				body: requestConfig.body ? JSON.stringify(requestConfig.body) : null,
			});
			if (!response.ok) {
				throw new HttpError('Request failed', response.status);
			}

			const data = await response.json();
			if (mounted.current) applyData(data);
		} catch (err: any) {
			console.error(err);
			const code = err.code || undefined;
			const message = err.message || "Something went wrong!";
			if (mounted.current) setError({message: message, code: code});
		}
		if (mounted.current) setIsLoading(false);
	}, [isLoggedIn, token]);
	return {
		isLoading,
		error,
		sendRequest,
	};
};

class HttpError {
	message: string;
	code?: number;
	constructor(message: string, code?: number) {
		this.message = message;
		this.code = code;
	}
}

export default useHttp;
