import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import styles from './ClickableContainer.module.css'

interface ClickableContainerProps {
    to: string;
    children?: React.ReactNode;
    className?: string;
}
 
const ClickableContainer: FunctionComponent<ClickableContainerProps> = (props) => {
    const classes = props.className ? `${styles.container} ${props.className}` : styles.container
    return ( <Link to={props.to} className={classes}>
        {props.children}
    </Link> );
}
 
export default ClickableContainer;